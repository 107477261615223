import type { TFunction, I$W, PlatformControllerFlowAPI, ILocation } from '@wix/yoshi-flow-editor';
import type { BiReporter } from 'root/utils/BiReporter';
import type menuNavigationModel from './model';
import type { ControllerParams } from 'root/types';
import { setElementDataAndCollapseIfNeeded } from 'root/utils/setElementDataAndCollapseIfNeeded';
import { getNavigationBarItems, getNavigationDropdownItems, isValidMenuQueryParam } from 'root/components/Menu/utils';
import { MENU_NAVIGATION_COMPONENT_IDS } from './consts';
import type { MenuNavigationWidgetProps } from './types';
import { NAVIGATION_STATES } from 'root/components/Menu/blocksPanels/MenuTabs/consts';

type Props = ControllerParams<typeof menuNavigationModel>['$props'];

export class MenuNavigationController {
  initMenuFn?: (menuId: string, queryParam: string) => void;
  setEmptyMenuStateFn?: () => void;
  t: TFunction;

  constructor(
    private $w: I$W,
    private flowAPI: PlatformControllerFlowAPI,
    private biReporter: BiReporter,
    private $props: Props,
    private location: ILocation
  ) {
    this.t = this.flowAPI.translations.t as TFunction;
    this.initMenuFn = undefined;
    this.setEmptyMenuStateFn = undefined;
  }

  setNavigationBarMenuItems(activeMenuId: string) {
    const { navigationBarItems, menusMap } = this.$props.data as MenuNavigationWidgetProps;
    const navigationBarMenuItems = getNavigationBarItems(
      navigationBarItems,
      menusMap,
      activeMenuId,
      true,
      this.location
    );
    setElementDataAndCollapseIfNeeded(this.$w, MENU_NAVIGATION_COMPONENT_IDS.tabs, 'menuItems', navigationBarMenuItems);
  }

  setNavigationDropdownOptions(activeMenuId: string) {
    const { navigationBarItems, menusMap } = this.$props.data as MenuNavigationWidgetProps;
    const navigationDropdownOptions = getNavigationDropdownItems(navigationBarItems, menusMap);
    const activeMenuIndexInDropdown = navigationDropdownOptions.findIndex((option) => option.value === activeMenuId);

    setElementDataAndCollapseIfNeeded(
      this.$w,
      MENU_NAVIGATION_COMPONENT_IDS.dropdown,
      'options',
      navigationDropdownOptions
    );

    this.$w(MENU_NAVIGATION_COMPONENT_IDS.dropdown).selectedIndex = activeMenuIndexInDropdown;
  }

  setInitMenuFn = (fn: (menuId: string, queryParam: string) => void) => {
    this.initMenuFn = fn;
  };

  setSetEmptyMenuFn = (fn: () => void) => {
    this.setEmptyMenuStateFn = fn;
  };

  init() {
    const { navigationBarItems, currentMenuId, menusMap } = this.$props.data as MenuNavigationWidgetProps;
    const navigationTabs = this.$w(MENU_NAVIGATION_COMPONENT_IDS.tabs);
    const navigationDropdown = this.$w(MENU_NAVIGATION_COMPONENT_IDS.dropdown);

    try {
      if (navigationBarItems.length > 1) {
        const currentSitePage = this.flowAPI.controllerConfig.wixCodeApi.site.currentPage?.name;

        this.setNavigationBarMenuItems(currentMenuId);
        this.setNavigationDropdownOptions(currentMenuId);

        navigationTabs.onItemClick(({ item }: { item: { link: string; id: string } }) => {
          const { id } = item;
          const activeMenuId = id;
          const tabsActiveMenuQueryParam = menusMap[id].urlQueryParam;

          this.setNavigationBarMenuItems(activeMenuId);

          this.biReporter.reportMenusHorizontalNavigationClickBi(
            navigationBarItems.length,
            currentSitePage,
            NAVIGATION_STATES.tabs
          );

          this.initMenuFn?.(activeMenuId || navigationBarItems[0], tabsActiveMenuQueryParam || '');

          this.setNavigationDropdownOptions(currentMenuId);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        navigationDropdown.onChange((event: any) => {
          const selectedMenuId = event.target.value;
          const isFirstMenu = navigationBarItems[0] === selectedMenuId;
          const dropdownActiveMenuQueryParam = menusMap[selectedMenuId].urlQueryParam;

          if (isValidMenuQueryParam(dropdownActiveMenuQueryParam)) {
            if (isFirstMenu) {
              this.location.queryParams.remove(['menu']);
            } else {
              this.location.queryParams.add({ menu: dropdownActiveMenuQueryParam });
            }
          } else {
            this.location.queryParams.add({ menuId: selectedMenuId });
          }

          this.biReporter.reportMenusHorizontalNavigationClickBi(
            navigationBarItems.length,
            currentSitePage,
            NAVIGATION_STATES.dropdown
          );

          this.initMenuFn?.(selectedMenuId || navigationBarItems[0], dropdownActiveMenuQueryParam || '');
        });
      } else if (navigationBarItems.length === 1) {
        navigationTabs.delete();
      } else {
        this.setEmptyMenuStateFn?.();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}
