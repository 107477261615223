import { MENU_WIDGET_COMPONENT_IDS, MENU_WIDGET_PRESET_IDS } from 'root/utils/consts';
import { getNavigationBarItems, isValidMenuQueryParam } from './utils';
import { setElementDataAndCollapseIfNeeded } from 'root/utils/setElementDataAndCollapseIfNeeded';
import { NAVIGATION_BAR_ARIA_LABEL, NAVIGATION_BAR_ROLE, MENU_STATES } from './consts';
import type { Preset, PopulatedMenu, ControllerParams } from 'root/types';
import type { MenusOrder, MenuState } from './types';
import type { I$W, ILocation, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { PlaceholderImageDisplayValue, ZeroPriceDisplayValue } from './panels/Settings/types';
import type { BiReporter } from 'root/utils/BiReporter';
import type menuModel from './model';
import { NAVIGATION_STATES } from './blocksPanels/MenuTabs/consts';

export type MenuProps = ControllerParams<typeof menuModel>['$props'];

export class MenuController {
  menus?: PopulatedMenu[];
  menusMap: Record<string, PopulatedMenu> = {};
  menu?: PopulatedMenu;

  constructor(
    private $w: I$W,
    private isViewer: boolean,
    private t: PlatformControllerFlowAPI['translations']['t'],
    private biReporter: BiReporter,
    private isMenusNavBarNoScrollExperimentEnabled: boolean
  ) {}

  init(
    menuId: string,
    menuQueryParam: string | undefined,
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[],
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    sectionsWithPlaceholderImageIds: string[],
    placeholderImage: string | undefined
  ) {
    this.setMenu(menuId, menuQueryParam);

    if (this.menu) {
      this.setMenuStateIfNeeded();
      this.setTitle(this.menu);
      this.setDescription(this.menu);
      this.setColumns(
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        zeroPriceDisplayOption,
        zeroPriceDisplaySpecificSectionIds,
        placeholderImageDisplayValue,
        sectionsWithPlaceholderImageIds,
        placeholderImage
      );
      this.deleteHiddenImageFromViewer(preset);
      return true;
    } else {
      return false;
    }
  }

  setMenu(menuId: string, menuQueryParam: string | undefined) {
    let menuToSet: PopulatedMenu | undefined;
    if (isValidMenuQueryParam(menuQueryParam)) {
      menuToSet = this.menus?.find(
        (menu: PopulatedMenu) =>
          menu.urlQueryParam === menuQueryParam || menu.urlQueryParam === encodeURI(menuQueryParam)
      );
    } else {
      menuToSet = this.menusMap[menuId];
    }

    this.menu = menuToSet;
  }

  setMenus(menus: PopulatedMenu[]) {
    this.menus = menus;
    this.menusMap = menus.reduce((acc, menu) => ({ ...acc, [menu.id as string]: menu }), {});
  }

  setMenuStateIfNeeded() {
    const menuState = MENU_STATES.menu;
    const currentState = this.$w(MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox).currentState;
    if (currentState.id !== menuState) {
      this.switchState(menuState);
    }
  }

  setErrorState() {
    this.switchState(MENU_STATES.error);
    this.$w(MENU_WIDGET_COMPONENT_IDS.errorStateTitle).text = this.t('component.menu.error-state');
  }

  setEmptyState() {
    this.switchState(MENU_STATES.empty);
    this.$w(MENU_WIDGET_COMPONENT_IDS.emptyStateTitle).text = this.t('component.menu.empty-state');
  }

  switchState(newState: MenuState) {
    const multiStateBox = this.$w(MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox);
    multiStateBox.changeState(newState);
  }

  setTitle(menu: PopulatedMenu) {
    this.$w(MENU_WIDGET_COMPONENT_IDS.menuTitle).text = menu.name;
  }

  setDescription(menu: PopulatedMenu) {
    setElementDataAndCollapseIfNeeded(this.$w, MENU_WIDGET_COMPONENT_IDS.menuDescription, 'text', menu.description);
  }

  setColumns(
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[],
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    sectionsWithPlaceholderImageIds: string[],
    placeholderImage: string | undefined
  ) {
    const { $w, menu } = this;
    if (!menu) {
      return;
    }
    $w(MENU_WIDGET_COMPONENT_IDS.columns).data = {
      sections: menu.sections,
      preset,
      shouldDisplayCurrency,
      shouldDisplayVariantCurrency,
      zeroPriceDisplayOption,
      zeroPriceDisplaySpecificSectionIds,
      placeholderImageDisplayValue,
      sectionsWithPlaceholderImageIds,
      placeholderImage,
    };
  }

  async setNavigationBar(
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[],
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    sectionsWithPlaceholderImageIds: string[],
    location: ILocation,
    flowAPI: PlatformControllerFlowAPI,
    placeholderImage: string | undefined,
    menusOrder?: MenusOrder
  ) {
    const { $w, menusMap, menus } = this;
    const { experiments } = flowAPI;
    const currentSitePage = flowAPI.controllerConfig.wixCodeApi.site.currentPage?.name;
    const isConvertNavigationBarToWidgetExperimentEnabled = experiments.enabled(
      'specs.restaurants.convertNavigationBarToWidget'
    );
    const shouldShowAllMenusByDefault = !menusOrder;
    const navigationBarItems = shouldShowAllMenusByDefault
      ? menus?.map((menu) => menu.id as string) || []
      : menusOrder.filter((id) => !!menusMap[id]);

    if (isConvertNavigationBarToWidgetExperimentEnabled) {
      $w(MENU_WIDGET_COMPONENT_IDS.navigationBar).delete?.();
      $w(MENU_WIDGET_COMPONENT_IDS.navigationMultiStateBox).delete?.();
      $w(MENU_WIDGET_COMPONENT_IDS.navigationWidget).data = {
        navigationBarItems,
        menusMap,
        currentMenuId: this.menu?.id,
      };

      $w(MENU_WIDGET_COMPONENT_IDS.navigationWidget).setInitMenuFn((menuId: string, queryParam: string) => {
        this.init(
          menuId,
          queryParam,
          preset,
          shouldDisplayCurrency,
          shouldDisplayVariantCurrency,
          zeroPriceDisplayOption,
          zeroPriceDisplaySpecificSectionIds,
          placeholderImageDisplayValue,
          sectionsWithPlaceholderImageIds,
          placeholderImage
        );
      });

      $w(MENU_WIDGET_COMPONENT_IDS.navigationWidget).setSetEmptyMenuFn(() => this.setEmptyState());
    } else {
      $w(MENU_WIDGET_COMPONENT_IDS.navigationWidget).delete?.();
    }

    const navigationBar = $w(MENU_WIDGET_COMPONENT_IDS.navigationBar);

    navigationBar.accessibility.ariaAttributes.label = NAVIGATION_BAR_ARIA_LABEL;
    navigationBar.accessibility.role = NAVIGATION_BAR_ROLE;

    const setNavigationBarMenuItems = (activeMenuId: string) => {
      const navigationBarMenuItems = getNavigationBarItems(
        navigationBarItems,
        menusMap,
        activeMenuId,
        this.isMenusNavBarNoScrollExperimentEnabled,
        location
      );
      setElementDataAndCollapseIfNeeded(
        $w,
        MENU_WIDGET_COMPONENT_IDS.navigationBar,
        'menuItems',
        navigationBarMenuItems
      );
    };

    // Hotfix for navigation bar in vertical menu does not exist - should investigate
    try {
      if (navigationBarItems.length > 1) {
        setNavigationBarMenuItems(this.menu?.id as string);

        navigationBar.onItemClick(({ item }: { item: { link: string; id: string } }) => {
          const { id } = item;
          const activeMenuId = id;
          const activeMenuQueryParam = menusMap[id].urlQueryParam;

          setNavigationBarMenuItems(activeMenuId);

          this.biReporter.reportMenusHorizontalNavigationClickBi(
            navigationBarItems.length,
            currentSitePage,
            NAVIGATION_STATES.tabs
          );

          this.init(
            activeMenuId || navigationBarItems[0],
            activeMenuQueryParam,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            zeroPriceDisplaySpecificSectionIds,
            placeholderImageDisplayValue,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );
        });
      } else if (navigationBarItems.length === 1) {
        navigationBar.delete();
      } else {
        this.setEmptyState();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  deleteHiddenImageFromViewer(preset: Preset) {
    const shouldDeleteImage = preset !== MENU_WIDGET_PRESET_IDS.sideBySideOneColumn && this.isViewer;
    if (shouldDeleteImage) {
      this.$w(MENU_WIDGET_COMPONENT_IDS.sbsImage).delete();
    }
  }
}
